import React from "react";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import Image from "components/Image";
import SwiperNav from "components/SwiperNav";

SwiperCore.use([Navigation]);

const Gallery = ({ items, theme }) => {
  if (!theme) {
    theme = null;
  }

  return (
    <Swiper
      className="relative"
      navigation={{
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      }}
      slidesPerView={`auto`}
      loop={true}
    >
      {items.map((item) => {
        return (
          <SwiperSlide key={item.id}>
            <div className="flex aspect-w-6 aspect-h-3">
              <div>
                <Image image={item} className={`object-cover w-full h-full`} />
              </div>
            </div>
          </SwiperSlide>
        );
      })}
      <SwiperNav theme={theme || "stormdust"} />
    </Swiper>
  );
};

export default Gallery;
