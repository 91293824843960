import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const SwiperNav = ({ theme = "cinnabar" }) => {
  return (
    <div
      className={`theme-${theme} absolute z-10 bottom-0 left-0 flex text-skin-base bg-skin-fill/75`}
    >
      <div
        className={`swiper-prev group cursor-pointer px-2 py-3 font-bold text-xl hover-hover:hover:bg-skin-fill`}
      >
        <BsChevronLeft />
      </div>
      <div
        className={`swiper-next group cursor-pointer px-2 py-3 font-bold text-xl hover-hover:hover:bg-skin-fill`}
      >
        <BsChevronRight />
      </div>
    </div>
  );
};

export default SwiperNav;
