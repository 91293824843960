import React from "react";
import { Link, graphql } from "gatsby";
import { BsArrowRight } from "react-icons/bs";
import Image from "components/Image";

import Layout from "components/bespoke/Layout";
import Gallery from "components/Gallery";

export const query = graphql`
  query ($id: ID!) {
    wp {
      project(id: $id) {
        id
        title
        content
        featuredImage {
          node {
            ...MediaItem
          }
        }
        singleProject {
          detail {
            client
            duration
            location
            value
            nextProject {
              ... on WP_Project {
                uri
              }
            }
          }
          gallery {
            ...MediaItem
          }
        }
      }
    }
  }
`;

const Project = ({ data }) => {
  if (!data) return null;
  const {
    title,
    content,
    singleProject: {
      detail: { location, value, duration, nextProject },
      gallery,
    },
  } = data.wp.project;
  return (
    <Layout title={title}>
      <div className="bg-two-tone from-firefly to-white">
        <div className="md:container mx-6 md:mx-auto md:text-center text-white">
          <header>
            <h1 className=" font-bold uppercase text-[40px] tracking-wider">
              {title}
            </h1>
            <p className="text-3xl font-light uppercase">{location}</p>
          </header>
          <div className="my-16">
            {gallery ? (
              <Gallery items={gallery} />
            ) : (
              <div className="flex aspect-w-6 aspect-h-3">
                <div>
                  <Image
                    image={
                      data.wp.project.featuredImage &&
                      data.wp.project.featuredImage.node
                    }
                    className={`object-cover w-full h-full`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="md:container mx-6 md:mx-auto mb-20">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:pl-12 pr flex flex-col gap-4 text-firefly-500 md:text-right">
            {value && (
              <div className="tracking-wider uppercase text-xl">
                <p className="font-bold">Project value</p>
                <p className="font-light">{value}</p>
              </div>
            )}
            {duration && (
              <div className="tracking-wider uppercase text-xl">
                <p className="font-bold">Timeframe</p>
                <p className="font-light">{duration}</p>
              </div>
            )}
          </div>
          <div
            className="flex-1 space-y-6 text-stormdust sm:pr-6 md:pr-12 lg:pr-0"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
        {nextProject && (
          <div className="mt-16 flex justify-center gap-16">
            <Link
              to={`${nextProject.uri}`}
              className={`flex items-center text-2xl font-bold hover:underline text-stormdust uppercase`}
            >
              {" "}
              Next project
              <BsArrowRight className={`ml-2 text-4xl text-firefly-500`} />
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Project;
